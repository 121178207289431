import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/login/loginPage.vue";
import { getToken, removeToken } from "@/api/token";
import { getUserInfo } from "@/api/user";

Vue.use(VueRouter);

const routes = [
  // 将/ 重定向到/home
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/home",
    name: "home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/main/home.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// 导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    next();
  } else {
    let token = getToken("token");
    if (token === null || token === "") {
      removeToken("token");
      next("/login");
    } else {
      getUserInfo()
        .then((res) => {
          if (res.code === 200) {
            next();
          } else {
            removeToken("token");
            next("/login");
          }
        })
        .catch((err) => {
          removeToken("token");
          next("/login");
        });
    }
  }
});

export default router;
