import request from '@/api/request'

export function login(data) {
    return request({
        method: 'post',
        url: '/user/login',
        data: data //可以简写为data
    })
}
export function register(data) {
    return request({
        method: 'post',
        url: '/user/register',
        data: data //可以简写为data
    })
}
export function logout(data) {
    return request({
        method: 'get',
        url: '/user/logout',
    })
}
export function getUserInfo(data) {
    return request({
        method: 'get',
        url: '/user/info',
    })
}

export function registerStatus(data) {
    return request({
        method: 'get',
        url: '/user/registerStatus',
    })
}

