<template>
  <div class="center">
    <div :class="logonCls">
      <div :class="overlaylong">
        <div class="overlaylong-Signin" v-if="disfiex == 0">
          <h2 class="overlaylongH2">登陆</h2>
          <el-form class="input_form" :model="login" :rules="rules">
            <el-form-item prop="username">
              <el-input v-model="login.username" clearable placeholder="请输入用户名" prefix-icon="el-icon-user"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" prefix-icon="el-icon-lock" placeholder="请输入密码" v-model="login.password"
                show-password />
            </el-form-item>

          </el-form>
          <button class="inupbutton" @click="userLogin">登陆</button>
        </div>
        <div class="overlaylong-Signup" v-if="disfiex == 1">
          <h2 class="overlaylongH2">注册账户</h2>
          <el-form class="input_form" :model="register" :rules="rules">
            <el-form-item prop="username" >
              <el-input type="text" v-model="register.username" placeholder="请输入用户名"/>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" prefix-icon="el-icon-lock" placeholder="请输入密码" v-model="register.password"
                show-password />
            </el-form-item>
            <el-form-item prop="invitationCode">
              <el-input type="text" v-model="register.invitationCode" placeholder="请输入邀请码" />
            </el-form-item>
          </el-form>
          <button class="inupbutton" @click="userRegister" :class="{ 'disabled': !buttonEnabled }" >注册</button>
        </div>

      </div>
      <div :class="overlaytitle">
        <div class="overlaytitle-Signin" v-if="disfiex == 0">
          <h2 class="overlaytitleH2">欢迎来到ISearch！</h2>
          <p class="overlaytitleP">
            注册新账号，开始你的搜索之旅
          </p>
          <div class="buttongohs" @click="Signin" :class="{ 'disabled': !buttonEnabled }">注册</div>
        </div>
        <div class="overlaytitle-Signup" v-if="disfiex == 1">
          <h2 class="overlaytitleH2">已有账户？请登录</h2>
          <p class="overlaytitleP"> </p>
          <div class="buttongohs" @click="Signup">登陆</div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import '@/style/login/LoginPage.css'
import { login, register, registerStatus } from '@/api/user/index'
import { setToken } from '@/api/token'
export default {
  data() {
    return {
      rules: {
        username: [
          { required: true, message: "必填" },
          { min: 4, max: 18, message: '用户名长度在 4 到 18 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: "必填" },
          { min: 6, max: 18, message: '密码长度在 6 到 18 个字符', trigger: 'blur' }
        ],
        invitationCode: [
          { required: true, message: "必填" },
        ]
      },
      login: {
        username: '',
        password: ''
      },
      register: {
        username: '',
        password: '',
        invitationCode: ''
      },
      overlaylong: 'overlaylong',
      overlaytitle: 'overlaytitle',
      disfiex: 0,
      buttonEnabled: true,
      logonCls: 'logon',
    }
  },
  methods: {
    getRegisterStatus() {
      registerStatus().then(res => {
        let { code, data, msg } = res
        if (code === 200) {
          this.buttonEnabled = data.registerStatus == 1 ? true : false
        } else {
          this.$message.error(msg)
        }
      })
    },
    userLogin() {
      login({ username: this.login.username, password: this.login.password }).then(res => {
        let { code, data, msg } = res
        // 判断是否登陆成功
        if (code === 200) {
          // 登陆成功
          this.$message.success('登陆成功')
          setToken("token", data.token)
          // 将用户名和角色存储到sessionStorage中
          sessionStorage.setItem('username', data.user.username)
          sessionStorage.setItem('role', data.user.role)
          this.$router.push({ path: '/home' })
        } else {
          // 登陆失败
          this.$message.error(msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    userRegister() {
      console.log({ username: this.register.username, password: this.register.password, invitationCode: this.register.invitationCode })
      register({ username: this.register.username, password: this.register.password, invitationCode: this.register.invitationCode }).then(res => {
        let { code, data, msg } = res
        // 判断是否登陆成功
        if (code === 200) {
          // 登陆成功
          this.$message.success('注册成功')
        } else {
          // 登陆失败
          this.$message.error(msg)
        }
      })
    },
    
    Signin() {
      if (!this.IsMobile()) {
        this.overlaylong = "overlaylongleft"
        this.overlaytitle = "overlaytitleright"
      }
      setTimeout(() => {
        this.disfiex = 1
      }, 200)
    },
    Signup() {
      if (!this.IsMobile()) {
        this.overlaylong = "overlaylongright"
        this.overlaytitle = "overlaytitleleft"
      }
      setTimeout(() => {
        this.disfiex = 0
      }, 200)
    },
    IsMobile() {
      return window.innerWidth <= 768
    },
  },
  mounted() {
    if (this.IsMobile()) {
      this.logonCls = 'logon-mobile'
      this.overlaylong = 'overlaylong-top'
      this.overlaytitle = 'overlaylong-bottom'
    }
    window.addEventListener('resize', () => {
      if (this.IsMobile()) {
        this.logonCls = 'logon-mobile'
        this.overlaylong = 'overlaylong-top'
        this.overlaytitle = 'overlaylong-bottom'
      } else {
        this.logonCls = 'logon'
        this.overlaylong = 'overlaylong'
        this.overlaytitle = 'overlaytitle'
      }
    })
    this.getRegisterStatus()
  }
}
</script>

<style>
.disabled {
  pointer-events: none;
  /* 禁止点击 */
  opacity: 0.6;
  /* 按钮不可点击时的透明度 */
  cursor: not-allowed;
  /* 鼠标指针样式 */
}
</style>
