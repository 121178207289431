import axios from "axios";
import { getToken, removeToken } from "./token.js";
import { Message } from "element-ui";
import router from "@/router";
// 创建axios实例
const request = axios.create({
  // 这里可以放一下公用属性等。
  baseURL: "https://ai.mysearch.top/", // 用于配置请求接口公用部分，请求时会自动拼接在你定义的url前面。
  withCredentials: false, // 跨域请求时是否需要访问凭证
  timeout: 300 * 1000, // 请求超时时间
  headers: {
    // 'X-Custom-Header': 'foobar',
    // 'Content-Type': 'application/json;charset=UTF-8'
  },
});
// 请求拦截器
request.interceptors.request.use(
  (config) => {
    //token名称以自己的为定，我的是‘satoken’，如果不需要if这里就可以直接删掉
    if (getToken("token")) {
      config.headers["Authorization"] = "Bearer " + getToken("token"); //携带token
      config.headers["Content-type"] = "application/json";
    }
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    return response.data; //此处可以只返回后端传出的数据（第一层data是axios封装的）
  },
  (error) => {
    console.log("err" + error); // for debug
    const status = error.response.status;
    if (status === 401) {
      Message({
        message: "登录过期，请重新登录",
        type: "error",
        duration: 3 * 1000,
      });
      // 判断状态码是401 跳转到登录
      router.replace({ path: "/login" });
      return;
    } else if (status === 403) {
      Message({
        message: "没有权限",
        type: "error",
        duration: 3 * 1000,
      });
      return;
    } else if (status === 500) {
      Message({
        message: "服务器错误",
        type: "error",
        duration: 3 * 1000,
      });
      return;
    }
    return Promise.reject(error);
  }
);

export default request;
